<template>
    <v-chart style="width: 100%" class="chart col-md-12" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "HelloWorld",
  props: ["datalebel"],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "Innovation Center",
              "DT Institute",
              "NICTSIM System",
              "e-Commerce",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Last Month",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series",
            },
            data: [320, 332, 301, 334, 390, 320, 332, 301, 334, 89, 330, 320],
          },
          {
            name: "This Month",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series",
            },
            data: [120, 320, 332, 301, 334, 89, 132, 101, 134, 90, 230, 210],
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>




